export const INVOICE_STATUS_ENUM = {
    CREATED: 'CREATED',
    REJECTED: 'REJECTED',
    INIT: 'INIT', // платеж создан
    ERROR: 'ERROR', // ошибка
    PROCESSED: 'PROCESSED', // когда деньги зашли полностью
    PENDING: 'PENDING', // платеж частично оплачен
    PARTIAL: 'PARTIAL', // платеж частично оплачен и время истекло
    EXPIRED: 'EXPIRED', // платеж частично оплачен и время истекло
};
