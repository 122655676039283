import axios from "axios";

export const invoicePay = async ({ state, rootGetters }) => {
    const lang = state.lang
    const theme = state.themeStatus

    try {
        const orderResponse = await axios.post('/public-api/invoice/' + state.invoice.id, {
            currency: state.currency.currency,
            network: state.currency.network,
            amount: state.currency.amount,
        });

        const {code, result} = orderResponse.data;
        const host = import.meta.env.VITE_CHECKOUT_HOST
        const resId = result.split('/').slice(3)
        const link = `${host}/${resId}`
        console.log(link, 555)
        if (code === 0) {
            window.location.href = `${link}?theme=${theme ? 'dark' : 'light'}&lang=${lang}`;
        }
    } catch (e) {
        console.error('Fail fetch invoice data', e);
    } finally {
        console.log('error');
    }
};
